import React, { useState, useEffect } from "react";
import { ListofModels } from "./ListofModels";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getmymodel,
  changemodelstatus,
  getverifyhost,
} from "../../app/features/model/modelSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Modal from "../../components/modals";
import { FaAngleLeft } from "react-icons/fa";
import CategoryButtomCompoent from "./components/categoryButton";
import SearchInput from "./components/searchInput";

export const Model = ({ genderSearchQuery }) => {
  const login = useSelector((state) => state.register.logedin);
  const dispatch = useDispatch();
  const mymodelstatus = useSelector((state) => state.model.mymodelstatus);
  const message = useSelector((state) => state.model.message);
  const mymodel = useSelector((state) => state.model.mymodel);
  const userid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);
  const ListofVerihost = useSelector((state) => state.model.ListofLivehost);
  const Listofhoststatus = useSelector((state) => state.model.Listofhoststatus);
  const [searchQuery, setSearchQuery] = useState("");
  const [nameSearchQuery, setNameSearchQuery] = useState("");
  const [hosttypeSearchQuery, setHosttypeSearchQuery] = useState("");
  const [locationSearchQuery, setLocationSearchQuery] = useState("");
  const [Bookclick, setbookclick] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showCountires, setShowCountrires] = useState(false);
  const [countries, setCountries] = useState([]);
  const [displayedCountries, setDisplayedCountries] = useState([]);
  let [loading, setLoading] = useState(true);
  let [loading1, setLoading1] = useState(true);
  let [showmymodey, setshowmymodel] = useState(false);
  let [showhost, setshowhost] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [categoryButton, setCategoryButton] = useState(" ");

  console.log(genderSearchQuery);

  const buttonData = [
    { label: "Private Show", value: "Private Show" },
    { label: "Fan meet", value: "Fan meet" },
    { label: "Fan date", value: "Fan date" },
  ];

  const toggleModal = () => {
    setShowCountrires((prev) => !prev);
  };

  const handleCategorybutton = (value) => {
    setCategoryButton(value);
    setHosttypeSearchQuery(value);
  };

  const toggleFullPageModal = () => {
    setModalOpen(!isModalOpen);
  };

  // Filter countries based on search query
  const filteredCountries = displayedCountries.filter((country) =>
    country.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // const normalizedLocationQuery =
  //   locationSearchQuery === "All" ? null : locationSearchQuery;

  const filterModels = ListofVerihost.filter((model) => {
    const matchesName = nameSearchQuery
      ? model.name.toLowerCase().includes(nameSearchQuery.toLowerCase())
      : true;

    const matchesHostType = hosttypeSearchQuery
      ? model.hosttype.toLowerCase().includes(hosttypeSearchQuery.toLowerCase())
      : true;

    const matchesGender = genderSearchQuery
      ? model.gender.includes(genderSearchQuery)
      : true;

    const matchesLocation = locationSearchQuery
      ? model.location.toLowerCase().includes(locationSearchQuery.toLowerCase())
      : true;
    return matchesName && matchesLocation && matchesHostType && matchesGender;
  });

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        const formattedCountries = data.map((country) => ({
          name: country.name.common,
          flag: country.flags.svg,
        }));

        const countriesWithAll = [
          { name: "All", flag: "../../icons/Mappamondo.svg" },
          ...formattedCountries,
        ];
        setDisplayedCountries(countriesWithAll);
        setCountries(countriesWithAll);
      } catch (error) {
        console.log("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  // const modelsuv = () => {
  //   if (loading) {
  //     return (
  //       <>
  //         <SkeletonTheme baseColor="#202020" highlightColor="#444" height={30}>
  //           <p>
  //             <Skeleton count={6} />
  //           </p>
  //         </SkeletonTheme>
  //       </>
  //     );
  //   } else {
  //     mymodel.map((value) => {
  //       if (value.verify === "notlive" || value.verify === "rejected") {
  //         return (
  //           <Mymodels
  //             name={value.name}
  //             location={value.location}
  //             age={value.age}
  //             interestedin={value.interestedin}
  //             smoke={value.smoke}
  //             drink={value.drink}
  //             height={value.height}
  //             weight={value.weight}
  //             verify={value.verify}
  //             price={value.price}
  //             hostid={value.hostid}
  //             photolink={value.photolink}
  //           />
  //         );
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    if (mymodelstatus !== "loading") {
      dispatch(getmymodel({ userid, token }));
    }

    if (Listofhoststatus !== "loading") {
      dispatch(getverifyhost({ token }));
    }
  }, []);

  useEffect(() => {
    if (mymodelstatus === "succeeded") {
     
      dispatch(changemodelstatus("idle"));
      setLoading(false);
      if (mymodel.length > 0) {
        setshowmymodel(true);
      }
    }

    if (mymodelstatus === "failed") {
      
      dispatch(changemodelstatus("idle"));
    }

    if (Listofhoststatus === "succeeded") {
      
      dispatch(changemodelstatus("idle"));
      setLoading1(false);
      setshowhost(true);
    }

    if (Listofhoststatus === "failed") {
      
      dispatch(changemodelstatus("idle"));
    }
  }, [mymodelstatus, Listofhoststatus]);

  const Listofmodels = () => {
    if (loading1) {
      return (
        <>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <div className="w-full p-4 space-y-4">
      

        {/* Grid Section (2x2) */}
        <div className="grid grid-cols-2 gap-2">
          {Array(4)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className="relative flex flex-col items-center p-4 bg-[#121212] rounded-lg"
              >
             

                {/* Avatar Placeholder */}
                <Skeleton width={150} height={250} className="rounded-lg" />

                {/* Name and Label */}
                <div className="mt-3 text-left gap-4 flex items-start">
                  <Skeleton width={30} height={15} />
                  <Skeleton width={30} height={15} />
                  <Skeleton width={30} height={15} className="mt-1" />
                </div>
              </div>
            ))}
        </div>
      </div>
    </SkeletonTheme>
        </>
      );
    } else if (filterModels.length === 0) {
      return (
        <div>
          <h3 className="text-slate-400 text-center pt-20">No Model!</h3>
        </div>
      );
    } else {
      if (ListofVerihost) {
        return (
          <div className=" md:mt-12 overflow-auto">
            <ul className="grid grid-cols-2 md:grid-cols-3 gap-2 mb-12">
              {filterModels.map((value) => {
                if (value.verify === "live") {
                  return (
                    <ListofModels
                      key={value.hostid}
                      photolink={value.photolink}
                      hosttype={value.hosttype}
                      online={value.online}
                      name={value.name}
                      age={value.age}
                      gender={value.gender}
                      location={value.location}
                      interest={value.interestedin}
                      amount={value.amount}
                      modelid={value.hostid}
                      userid={value.userid}
                    />
                  );
                }
              })}
            </ul>
          </div>
        );
      }
    }
  };

  return (
    <div className="bg-black px-4 sm:m-10">
      <div className="text-slate-200 sm:w-1/2 sm:ml-16 md:w-full md:ml-0 md:mt-10 md:overflow-auto" >
        <div>
        
            <div className="mb-2 mx-auto">

              <div className="sm:p-0">
                <div className="flex gap-4">
                  <CategoryButtomCompoent
                    buttons={buttonData}
                    selected={categoryButton}
                    onButtonClick={handleCategorybutton}
                  />
              </div>
              
              {login && (
                <div className="flex flex-col">
                  <div className="flex justify-between items-center">
                    <div
                      className={`flex items-center ${
                        categoryButton === " " ? "invisible" : ""
                      }`}
                    >
                      <FaAngleLeft
                        color="green"
                        size={20}
                        onClick={() => {
                          handleCategorybutton(" ");
                          setHosttypeSearchQuery(null);
                        }}
                      />
                      <h3 className="text-green-600 font-bold text-md">
                        #{categoryButton.toLowerCase()}
                      </h3>
                    </div>

                    <button
                      className="text-blue-300  hover:underline focus:outline-none mt-2"
                      onClick={() => setShowOptions(!showOptions)}
                    >
                      {showOptions === false ? "Options" : "Hide Options"}
                    </button>
                  </div>

                  {showOptions && (
                    <SearchInput
                      nameSearchQuery={nameSearchQuery}
                      setNameSearchQuery={(e) =>
                        setNameSearchQuery(e.target.value)
                      }
                      toggleModal={toggleModal}
                      locationSearchQuery={locationSearchQuery}
                    />
                  )}

                  {showCountires && showOptions && (
                    <div>
                      <div className="flex flex-row justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold ">Regions</h3>
                        <button
                          onClick={toggleFullPageModal}
                          className="text-orange-500  px-4 py-2 rounded disabled:opacity-50"
                        >
                          More
                        </button>
                      </div>
                      <div className="flex flex-wrap gap-4">
                        {displayedCountries
                          .slice(0, 10)
                          .map((country, index) => (
                            <button
                              key={index}
                              className="bg-white border rounded-full flex items-center p-1 gap-2 
                               hover:bg-slate-200 hover:text-white"
                              onClick={() => {
                                setLocationSearchQuery(
                                  country.name === "All" ? null : country.name
                                );
                                toggleModal();
                              }}
                            >
                              <img
                                src={country.flag}
                                alt={`${country.name} flag`}
                                className="w-3 h-3 object-cover rounded-full"
                              />
                              <span className="text-black text-xs">
                                {country.name}
                              </span>
                            </button>
                          ))}
                      </div>
                    </div>
                  )}
              </div>
          )}
              
              </div>
            </div>

          {
            <div className="pb-11" >
              {Bookclick && (
                <div className="z-40">
                  <Bookclick />
                </div>
              )}
              {Listofmodels()}
            </div>
          }
        </div>

        <Modal
          isOpen={isModalOpen}
          title="All Countries"
          children={
            <div>
              <input
                placeholder="Search countries.."
                className="w-full border border-slate-200 rounded-lg py-2 px-2 placeholder:px-2 text-black"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <div className="pt-4 overflow-y-scroll h-80">
                <div className="flex flex-wrap gap-4  ">
                  {filteredCountries.map((country, index) => (
                    <button
                      key={index}
                      className="bg-white border border-slate-300 rounded-full flex items-center p-1 gap-2 hover:bg-slate-200
                       hover:text-white focus:bg-black focus:text-white"
                      onClick={() => {
                        setLocationSearchQuery(country.name);
                        toggleFullPageModal();
                        toggleModal();
                      }}
                    >
                      <img
                        src={country.flag}
                        alt={`${country.name} flag`}
                        className="w-3 h-3 object-cover rounded-full"
                      />
                      <span className="text-black text-xs">{country.name}</span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          }
          onClose={toggleFullPageModal}
        />
      </div>
    </div>
  );
};
