import React from "react";
import Breadcrumbs from "./components/breadcrumb";
import TitleSection from "./components/titleSection";
import ArticleList from "./components/articlelist";
import SearchBar from "./components/searchbar";
import Header from "./components/header";
import { FaGraduationCap } from "react-icons/fa";

export const AccountGold = () => {
  const articles = [
    { title:"How do I buy Golds?" ,ref:"/Buygold"},
     {title :"Conversion Rates:",ref:"/Converterate"},
    
     
   ];

   return (
    <div className="bg-black min-h-screen text-white ">
      <Header />
      <SearchBar />

      <div className="p-4">
        <Breadcrumbs title="Golds" />
        <TitleSection
          title="Golds"
          article="2 articles"
          icon={<FaGraduationCap color="" size={30} />}
        />

          <ArticleList articles={articles} />
        
      </div>
    </div>
  );
};
