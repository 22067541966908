import React, { useEffect, useState } from "react";
import person from "../../icons/person.svg";
import person2 from "../../icons/icons8-profile_Icon.png";

import backicon from "../../icons/backIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createpost, PostchangeStatus } from "../../app/features/post/post";

export const PostImage = ({ contents }) => {
  const photo = useSelector((state) => state.comprofile.profilephoto);
  const token = useSelector((state) => state.register.refreshtoken);
  const firstname = useSelector((state) => state.profile.firstname);
  const lastname = useSelector((state) => state.profile.lastname);
  const nickname = useSelector((state) => state.profile.nickname);
  const userid = useSelector((state) => state.register.userID);
  const poststatus = useSelector((state) => state.post.poststatus);
  const message = useSelector((state) => state.post.message);
  const dispatch = useDispatch();

  const [content, setcontent] = useState(contents);
  const [photolink, setphotlink] = useState(person);
  const [imgsrc, setimgsrc] = useState();
  const [showimage, setshowimage] = useState(false);
  const [propics, setpropics] = useState(person2);
  const [username, setusername] = useState("");

  useEffect(() => {
    if (photo) {
      setpropics(photo);
    }
    if (nickname) {
      setusername(nickname);
    } else {
      let names = `${firstname} ${lastname}`;
      setusername(names);
    }
  });

  useEffect(() => {
    if (poststatus === "succeeded") {
      toast.dismiss();
      dispatch(PostchangeStatus("idle"));
      toast.success("Post upload successful", { autoClose: 2000 });
    }
    if (poststatus === "failed") {
      console.log("post error "+message)
      toast.dismiss();
      dispatch(PostchangeStatus("idle"));
    }
  }, [poststatus]);

  const postcontent = () => {
    if (!photolink) {
      toast.error("please choose a photo to post", { autoClose: false });
      return;
    }

    if (poststatus === "idle" && poststatus !== "loading") {
      toast.info("please wait", { autoClose: false });
      dispatch(
        createpost({
          userid,
          photolink: photolink,
          token,
          content,
          posttype: "image",
        })
      );
    }
  };

  return (
    <div className="mx-auto scroll-pr-3">
      <div className="flex flex-col pt-1 pb-1 h-fit">
        <div className="flex">
          <button
            onClick={(e) => {
              toast.dismiss();
            }}
          >
            <img alt="baclIcon" src={backicon} className="mb-2"></img>
          </button>

          <p className="text-slate-200 mb-2">Post</p>
        </div>

        <div className="flex pl-1 pt-1">
          <div className="w-fit h-fit bg-slate-500 rounded-full">
            <img
              alt="post image"
              src={propics}
              className="w-10 h-10 object-cover rounded-full"
            ></img>
          </div>
          <p className="ml-2 font-semibold text-slate-200">{username}</p>
        </div>

        {showimage && (
          <img
            className="object-cover w-1/2 mx-auto rounded-xl mt-1"
            alt="post image"
            src={imgsrc}
          ></img>
        )}

        <input
          type="file"
          className="mt-2 text-yellow-500"
          accept="image/*"
          onChange={(e) => {
            setshowimage(true);

            if (e.target.files[0]) {
              setimgsrc(URL.createObjectURL(e.target.files[0]));
              setphotlink(e.target.files[0]);
            }
          }}
        ></input>
        <div className="flex mt-2 flex-col ">
          <textarea
            className="textinpt placeholder:mt-2 w-2/3 mx-auto"
            placeholder="What is on your mind?"
            rows={5}
            value={content}
            onInput={(e) => {
              setcontent(e.currentTarget.value);
            }}
          ></textarea>
          <button
            className="btn mt-2 rounded-xl"
            onClick={() => {
              postcontent();
            }}
          >
            Post
          </button>
        </div>
      </div>
    </div>
  );
};
