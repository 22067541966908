import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { URL } from "../../../api/config";
import axios from "axios";
import { saveImage, deleteImage } from "../../../api/sendImage";

const initialState = {
  allPost: [],
  poststatus: "idle",
  message: "",
  error: null,
  getpostbyidstatus: "idle",
  deletepostsatus: "idle",
  postphoto: null,
};

export const createpost = createAsyncThunk("post/createpost", async (data) => {
  try {
    let infomfomation;
    let link;

    if (data.photolink) {
      console.log("inside post photo");
      console.log(" post photo " + data.photolink);
      link = await saveImage(data.photolink, "post");
      console.log("post photo link " + link);
    } else {
      link = "";
    }

    infomfomation = {
      userid: data.userid,
      postlink: link,
      content: data.content,
      token: data.token,
      posttype: data.posttype,
    };
    console.log("after info");

    //console.log('ontop get profile')
    let response = await axios.put(`${URL}/post`, infomfomation);
    // console.log('under get profile')

    return response.data;
  } catch (err) {
    // console.log('erro get profile')
    console.log("post err "+err)
    throw err.response.data.message;
  }
});

export const getallpost = createAsyncThunk("post/getallpost", async (data) => {
  try {
    console.log("after info");

    //console.log('ontop get profile')
    let response = await axios.post(`${URL}/getallpost`,data);
    // console.log('under get profile')

    return response.data;
  } catch (err) {
    // console.log('erro get profile')
    throw err.response.data.message;
  }
});

export const getpost = createAsyncThunk("post/getpost", async (data) => {
  try {
    console.log("after info");

    //console.log('ontop get profile')
    let response = await axios.get(`${URL}/getallpost`,data);
    // console.log('under get profile')

    return response.data;
  } catch (err) {
    // console.log('erro get profile')
    throw err.response.data.message;
  }
});

export const getpostbyid = createAsyncThunk(
  "post/getpostbyid",
  async (data) => {
    try {
      console.log("after info");

      //console.log('ontop get profile')
      let response = await axios.post(`${URL}/post`, data);
      // console.log('under get profile')

      return response.data;
    } catch (err) {
      // console.log('erro get profile')
      throw err.response.data.message;
    }
  }
);

export const deletepost = createAsyncThunk("post/deletepost", async (data) => {
  try {
    //console.log('ontop get profile')
    let response = await axios.patch(`${URL}/post`, data);
    // console.log('under get profile')

    let postphoto = response.data.post.postphoto;

    if (postphoto) {
      deleteImage(postphoto, "post");
    }

    return response.data;
  } catch (err) {
    // console.log('erro get profile')
    throw err.response.data.message;
  }
});

const post = createSlice({
  name: "post",
  initialState,
  reducers: {
    PostchangeStatus(state, action) {
      state.poststatus = action.payload;
      state.getpostbyidstatus = action.payload;
      state.deletepostsatus = action.payload;
    },
    emptypostphoto(state, action) {
      state.postphoto = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createpost.pending, (state, action) => {
        state.poststatus = "loading";
      })
      .addCase(createpost.fulfilled, (state, action) => {
        state.poststatus = "succeeded";
        state.message = action.payload.message;
        state.allPost.push(action.payload.post);

        state.allPost.reverse();
      })
      .addCase(createpost.rejected, (state, action) => {
        state.poststatus = "failed";

        if (!action.error.message) {
          state.error = "Check internet connection";
        } else {
          state.error = action.error.message;
        }
      })
      .addCase(getallpost.pending, (state, action) => {
        state.poststatus = "loading";
      })
      .addCase(getallpost.fulfilled, (state, action) => {
        state.poststatus = "succeeded";
        state.message = action.payload.message;

        if (Array.isArray(action.payload.post)) {
          state.allPost = action.payload.post;
        } else {
          state.allPost = [];
        }
      })
      .addCase(getallpost.rejected, (state, action) => {
        state.poststatus = "failed";

        if (!action.error.message) {
          state.error = "Check internet connection";
        } else {
          state.error = action.error.message;
        }
      })
      .addCase(getpostbyid.pending, (state, action) => {
        state.getpostbyidstatus = "loading";
      })
      .addCase(getpostbyid.fulfilled, (state, action) => {
        state.getpostbyidstatus = "succeeded";
        state.message = action.payload.message;

        let postindex = state.allPost.findIndex((value) => {
          return value.postid === action.payload.post.postid;
        });

        if (postindex !== -1) {
          state.allPost[postindex] = action.payload.post;
        }
      })
      .addCase(getpostbyid.rejected, (state, action) => {
        state.getpostbyidstatus = "failed";

        if (!action.error.message) {
          state.error = "Check internet connection";
        } else {
          state.error = action.error.message;
        }
      })
      .addCase(deletepost.pending, (state, action) => {
        state.deletepostsatus = "loading";
      })
      .addCase(deletepost.fulfilled, (state, action) => {
        state.deletepostsatus = "succeeded";
        state.message = action.payload.message;

        state.postphoto = action.payload.post.postphoto;

        let postindex = state.allPost.findIndex((value) => {
          return value.postid === action.payload.post.postid;
        });

        if (postindex !== -1) {
          state.allPost.splice(postindex, 1);
        }
      })
      .addCase(deletepost.rejected, (state, action) => {
        state.deletepostsatus = "failed";

        if (!action.error.message) {
          state.error = "Check internet connection";
        } else {
          state.error = action.error.message;
        }
      });
  },
});

export default post.reducer;
export const { PostchangeStatus, emptypostphoto } = post.actions;
