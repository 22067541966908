import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { Fail } from "../../fragment/Fail";
import { useSelector, useDispatch } from "react-redux";
import { PassEmail } from "./PassEmail";
import { toast, ToastContainer } from "react-toastify";
import {
  changecompleate,
  error,
  loginuser,
  savelogin,
  changelogin,
  forgetpass,
} from "../../app/features/register/registerSlice";
import { useNavigate } from "react-router-dom";

export const Loginview = ({ close }) => {
  const toastId = React.useRef(null);
  const message = useSelector((state) => state.register.message);
  const [open, setopen] = useState(false);
  const closeModal = () => setopen(false);
  const [closetoast, setClosetoast] = useState(false);
  const navigate = useNavigate();

  const alert = (message, type, close) => {
    toastId.current = toast(`${message}`, {
      type: `${type}`,
      autoClose: close,
    });
  };

  const dismissalert = () => {
    toast.dismiss(toastId.current);
  };

  const [email, setemail] = useState("");
  const dispatch = useDispatch();
  const [password, setpassword] = useState("");
  const [acceptedTerms , setAcceptedTerms] = useState(false)
  const errs = useSelector(error);

  const stats = useSelector((state) => state.register.logstats);
  const forgetpassstate = useSelector(
    (state) => state.register.forgetpassstate
  );

  useEffect(() => {
    if (message) {
      toast.success(`${message}`, { autoClose: 2000 });
      dispatch(
        changecompleate({
          compstats: "idle",
          message: "",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (stats === "succeeded") {
      dismissalert();
      alert(`${message}`, "success", true);
      dispatch(changelogin({ logstats: "idle", message: "" }));
      
      close();
    }
    if (stats === "failed") {
      dismissalert();
      alert(`${errs}`, "error", true);
      dispatch(changelogin({ logstats: "idle", message: "" }));
    }
  }, [stats, dispatch]);

  const checkinput =  () => {

    if(!acceptedTerms){
       toast.error("You must accept the terms and conditions to continue.", { autoClose: 2000 });
      return;
    }
    if (!email) {
      toast.error("Enter Email", { autoClose: 2000 });
      return;
    }
    if (!password) {
      toast.error("password Email", { autoClose: 2000 });
      return;
    }

    if (stats !== "loading") {
      dispatch(savelogin({ email, password }));
      alert("please wait..", "info", false);
       dispatch(
        loginuser({
          email,
          password,
        })
      );
    }
  };

  useEffect(() => {
    if (forgetpassstate === "succeeded") {
      dismissalert();
      alert(`${message}`, "success", true);
      toast(
        <PassEmail
          setClosetoast={setClosetoast}
          alert={alert}
          dismissalert={dismissalert}
          email={email}
          close={close}
        />,
        { autoClose: closetoast, pauseOnHover: false, pauseOnFocusLoss: false }
      );
    }
    if (forgetpassstate === "failed") {
      dismissalert();
      alert(`${errs}`, "error", true);
    }
  }, [forgetpassstate, dispatch]);

  const forget = async () => {
    if (!email) {
      toast.error("Enter Registered Email to Continue", { autoClose: 2000 });
      return;
    }
    if (forgetpassstate !== "loading") {
      alert("please wait..", "info", false);
       dispatch(
        forgetpass({
          email,
        })
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white px-4">
      <ToastContainer position="top-center" theme="dark" />
      <div className="bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-md">
        <h1 className="text-orange-500 text-3xl font-bold text-center">
          Welcome Back
        </h1>
        <p className="text-gray-400 text-center mt-2">
          Log in to access your account
        </p>

        <div className="mt-6 space-y-4">
          <input
            type="email"
            className="w-full px-4 py-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />

          <input
            type="password"
            className="w-full px-4 py-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="Password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
          />

          <div className="flex items-start mt-4">
            <input
              type="checkbox"
              id="terms"
              className="mr-2"
              checked={acceptedTerms}
              onChange={(e) => setAcceptedTerms(e.target.checked)}
            />
            <label htmlFor="terms" className="text-gray-400 text-sm">
              I accept the{" "}
              <span
                className="text-orange-500 underline cursor-pointer"
                onClick={() => navigate("/privacy_policy")}
              >
                Terms and Conditions
              </span>
            </label>
          </div>

          <button
            className="w-full bg-orange-500 hover:bg-orange-600 text-white py-2 rounded shadow transition"
            onClick={checkinput}
          >
            Log In
          </button>

          <p
            className="text-blue-500 text-sm text-center hover:text-blue-400 cursor-pointer"
            onClick={forget}
          >
            Forgot Password?
          </p>

          <p className="text-gray-400 text-sm text-center mt-4">
            Don't have an account?{" "}
            <span
              className="text-orange-500 font-bold hover:underline cursor-pointer"
              onClick={() => {
                navigate("/register");
                close();
              }}
            >
              Register
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
