import React from "react";
import { useSelector } from "react-redux";
import { Postlist } from "./Post/Postlist";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const Post = () => {
  const allPost = useSelector((state) => state.post.allPost);

  const checkpost = () => {
    if (allPost && allPost.length === 0) {
      return (
        <>
             <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <div className="flex flex-col gap-4 w-full">
            {Array(3)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className="bg-black p-4 rounded-md shadow-md w-full"
                >
                  {/* Profile Section */}
                  <div className="flex items-center gap-3 mb-2">
                    <Skeleton circle width={40} height={40} />
                    <div>
                      <Skeleton width={120} height={15} />
                      <Skeleton width={80} height={12} />
                    </div>
                  </div>

                  {/* Post Content */}
                  <Skeleton width="80%" height={20} className="mb-2" />

                  {/* Image Placeholder */}
                  <Skeleton width="100%" height={400} className="rounded-md" />

                  {/* Interaction Icons */}
                  <div className="flex justify-between mt-3">
                    <div className="flex gap-4"><Skeleton width={20} height={20} circle />
                    <Skeleton width={20} height={20} circle /></div>
                    <div>  <Skeleton width={20} height={20} circle /></div>
                    
                  
                  </div>
                </div>
              ))}
          </div>
        </SkeletonTheme>
        </>
      );
    } else {
      //console.log(allPost)
      return (
        <ul className="w-full mb-5">
          {allPost.map((value,index) => {
            //  console.log('retuned post id '+ value.postid)
            return (
              <Postlist
                postlog={value.postid}
                userphoto={value.userphoto}
                username={value.username}
                datetime={value.posttime}
                likes={value.like.length}
                comments={value.comment.length}
                postphoto={value.postphoto}
                content={value.content}
                posttype={value.posttype}
                postuserid={value.userid}
                likelist={value.like}
                nickname={value.nickname}
                isfollow={value.isfollow}
                key={`${index}_${value.postid}`}
              />
            );
          })}
        </ul>
      );
    }
  };

  return <div className=" mt-2  pl-2 sm:pr-28 pt-2 pb-20 ">{checkpost()}</div>;
};
