import React, { useState, useEffect } from "react";
import profileIcon from "../icons/icons8-profile_Icon.png";
import onlineIcon from "../icons/onlineIcon.svg";
import personsidebar from "../icons/personsidebar.svg";
import addIcon from "../icons/addIcon.svg";
import folowIcon from "../icons/icons8-footprintIcon.png";
import editIcon from "../icons/edit.svg";
import goldIcon from "../icons/icons8.png";
import reportIcon from "../icons/reportIcon.svg";
import feedback from "../icons/feedback.png";
import settings from "../icons/settings.png";
import question from "../icons/question.png";
import globeearth from "../icons/globeearth.png";
import video from "../icons/video.png";
import star from "../icons/star.png";
import verificationIcon from "../icons/verificationIcon.svg";
import withdrawIcon from "../icons/icons8-withdrawIcon.png";
import adminn from "../icons/adminn.png";
import usersIcon from "../icons/usersIcon.svg";
import logoutIcon from "../icons/logoutIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import "./Navs.css";
import { useNavigate, useLocation } from "react-router-dom";
import { resetprofilebyid } from "../app/features/profile/comprofile";
import manageIcon from "../icons/manageIcon.svg";
import messageIcon from "../icons/personalmessageIcon.svg";
import maleIcon from "../icons/man.png";
import femaleIcon from "../icons/woman.png";
import features from "../icons/features.svg";
import transIcon from "../icons/transIcon.svg";
import { IoIosTimer } from "react-icons/io";

export const Sidemenu = ({ open, handleMenubar }) => {
  const photo = useSelector((state) => state.comprofile.profilephoto);
  const firstname = useSelector((state) => state.profile.firstname);
  const [profile_photo, setprofile_photo] = useState(profileIcon);
  const postuserid = useSelector((state) => state.register.userID);
  const balance = useSelector((state) => state.profile.balance);
  const modelID = useSelector((state) => state.profile.modelID);
  const exclusive_verify = useSelector((state) => state.profile.exclusive_verify);
  const model = useSelector((state) => state.profile.model);
  const [gold_balance, setgold_balance] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [live, setLive] = useState(false)
  const [upgrade, setUpgrade] = useState(false)
  const formatter = new Intl.NumberFormat("en-US");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.profile.admin);

  useEffect(() => {
    if (photo) {
      setprofile_photo(photo);
    }

    if (balance) {
      let gold_b = formatter.format(parseFloat(balance));
      setgold_balance(gold_b);
    } else {
      setgold_balance("0");
    }
  });

  const location = useLocation().pathname;

  const Isnotmodel = () => {
    if (!model) {
      return true;
    } else {
      return false;
    }
  };


  const Ismodel = () => {
    if (model) {
      return true;
    } else {
      return false;
    }
  };

  const verify = ()=>{
    if(exclusive_verify){
      if(model){
        return <button
        className="flex  mt-2"
        onClick={(e) => navigate(`/modelbyid/${modelID}`)}
      >
        <img
          alt="manageIcon"
          src={manageIcon}
          className="w-7 h-7 object-cover"
        ></img>
        <p className="ml-1 mt-1">Model portfolio</p>
      </button>

      }else{

        return <button
        className="flex  mt-2"
        onClick={(e) => navigate("/createmodel")}
      >
        <img
          alt="manageIcon"
          src={manageIcon}
          className="w-7 h-7 object-cover"
        ></img>
        <p className="ml-1 mt-1">Model portfolio</p>
      </button>

      }
    }else{

      return <button
      className="flex  mt-2"
      onClick={(e) => navigate("/verification")}
    >
      <img
        alt="addIcon"
        src={addIcon}
        className="w-7 h-7 object-cover"
      ></img>
      <p className="ml-1 mt-1">Model Application</p>
    </button>

     
    }
  }

  const logout = () => {
    localStorage.removeItem("login");
    window.location.href = "/";
  };

  // const comingSoon = () => {
  //   return (
    
  //       <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
  //         <div className="bg-gray-300 p-5 rounded-lg shadow-lg text-center">
  //           <p className="text-lg font-semibold text-gray-800">Coming Soon</p>
  //           <button
  //             className="mt-4 bg-orange-600 text-white px-4 py-2 rounded font-semigold"
  //             onClick={() =>  setLive(false)}
  //           >
  //             OK
  //           </button>
  //         </div>
  //       </div>
  //     )
   
  // }

  return (
    <div className="fixed z-50">
      <div>
        <nav
          onClick={handleMenubar}
          className={`${
            open ? "block" : "hidden"
          } sm:block sm:h-[80%] md:h-[100%] sm:w-[35%] w-[80%] h-full bg-gray-800 text-white fixed top-0 
        right-0 transform origin-top-right sm:rounded-l-lg z-[70]`}
        >
          <div className="overflow-auto">
            <div className="flex flex-col items-start ml-1 mr-1">
              <div className="flex justify-between w-full">
                <div className=" flex text-blue-200 text-xs font-bold">
                  <img
                    alt="profileIcon"
                    src={profile_photo}
                    className="w-7 h-7 object-cover rounded-full"
                  />
                  <p className="ml-1 mt-1">{firstname}</p>
                </div>

                <div className="flex p-1 ">
                  <button onClick={(e) => {
                    e.stopPropagation();
                    setIsModalOpen(true)
                  }}>

                <IoIosTimer />
                  </button>
                  {/* <img alt="onlineIcon" src={onlineIcon} className="mb-2"></img> */}
                </div>
              </div>
              {/* time modal */}
               {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-300 p-5 rounded-lg shadow-lg text-center">
            <p className="text-lg font-semibold text-gray-800">You have 0 golds pending</p>
            <button
              className="mt-4 bg-orange-600 text-white px-4 py-2 rounded font-semigold"
              onClick={() =>  setIsModalOpen(false)}
            >
              OK
            </button>
          </div>
        </div>
      )}

              <div className="flex justify-between w-full  text-blue-200 text-xs font-bold">
                <div className=" flex">
                  <p className="ml-1 mt-1">status:</p>
                  <p className="ml-3 mt-1">Basic Member</p>
                </div>

                <button onClick={(e) => {e.stopPropagation(); setUpgrade(true)}}>
                <p className="ml-3 mt-1 hover:text-blue-400 active:text-blue-100" >
                  (Upgrade)
                </p></button>
              </div>
                {upgrade && (   <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-300 p-5 px-10 rounded-lg shadow-lg text-center">
            <p className="text-lg font-semibold text-gray-800">Coming Soon</p>
            <button
              className="mt-4 bg-orange-600 text-white px-4 py-2 rounded font-semigold"
              onClick={() =>  setUpgrade(false)}
            >
              OK
            </button>
          </div>
        </div>)}

              <div className="flex justify-between w-full  text-blue-200 text-xs font-bold">
                <div className=" flex">
                  <p className="ml-1 mt-1">You have:</p>
                  <p className="ml-3 mt-1">{gold_balance} Golds</p>
                </div>

                <p
                  className="ml-3 mt-1 hover:text-blue-400 active:text-blue-100 bt"
                  onClick={(e) => navigate("/topup")}
                >
                  (Get More)
                </p>
              </div>
            </div>
            <hr className="w-full ht mt-1 bg-blue-900"></hr>

            <div className="flex flex-col items-start ml-1 mr-1  text-white text-xs font-bold">
              <button
                className="flex  mt-3"
                onClick={(e) => {
                  if (location === `/profile/${postuserid}`) {
                    return;
                  }
                  dispatch(resetprofilebyid());
                  navigate(`/profile/${postuserid}`);
                  // console.log(location)
                }}
              >
                <img
                  alt="profilesidebar"
                  src={personsidebar}
                  className="w-7 h-7 object-cover"
                ></img>
                <p className="ml-1 mt-1">Profile</p>
              </button>

              {verify()}

             

              <button
                className="flex  mt-2"
                onClick={(e) => navigate("/following")}
              >
                <img
                  alt="star"
                  src={star}
                  className="w-6 h-6 object-cover"
                ></img>
                <p className="ml-1 mt-1">Following</p>
              </button>

              <button
                className="flex  mt-2"
                onClick={(e) => navigate("/editprofile")}
              >
                <img
                  alt="editIcon"
                  src={editIcon}
                  className="w-7 h-7 object-cover"
                ></img>
                <p className="ml-1 mt-1">Edit porfile</p>
              </button>

              <button className="flex  mt-2" onClick={(e) => { e.stopPropagation(); setLive(true) }}>
                <img
                  alt="video"
                  src={video}
                  className="w-6 h-6 object-cover"
                ></img>
                <p className="ml-1 mt-1">Live</p>
              </button>
            </div>
            {live && (   <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-300 p-5 px-10 rounded-lg shadow-lg text-center">
            <p className="text-lg font-semibold text-gray-800">Coming Soon</p>
            <button
              className="mt-4 bg-orange-600 text-white px-4 py-2 rounded font-semigold"
              onClick={() =>  setLive(false)}
            >
              OK
            </button>
          </div>
        </div>)}
            <hr className="w-full ht mt-1 bg-blue-900"></hr>
            <div className="flex flex-col items-start ml-1 mr-1  text-white text-xs font-bold">
              <button
                className="flex  mt-3"
                onClick={(e) => navigate("/goldstats")}
              >
                <img
                  alt="goldIcon"
                  src={goldIcon}
                  className="w-7 h-7 object-cover"
                ></img>
                <p className="ml-1 mt-1"> Gold Stats</p>
              </button>
            </div>

            {admin && (
              <div>
                <hr className="w-full ht mt-1 bg-blue-900"></hr>

                <div className="flex flex-col items-start ml-1 mr-1  text-white text-xs font-bold">
                  <button className="flex  mt-3">
                    <img
                      alt="reportIcon"
                      src={reportIcon}
                      className="w-7 h-7 object-cover"
                    ></img>
                    <p className="ml-1 mt-1">Reports</p>
                  </button>

                  <button
                    className="flex  mt-2"
                    onClick={(e) => {
                      navigate("/verifymodel");
                    }}
                  >
                    <img
                      alt="verificationIcon"
                      src={verificationIcon}
                      className="w-7 h-7 object-cover"
                    ></img>
                    <p className="ml-1 mt-1">Model verification</p>
                  </button>

                  <button className="flex  mt-2">
                    <img
                      alt="withdrawIcon"
                      src={withdrawIcon}
                      className="w-7 h-7 object-cover"
                    ></img>
                    <p className="ml-1 mt-1">Withdrawal request</p>
                  </button>

                  <button
                    className="flex  mt-2"
                    onClick={(e) => navigate("/viewusers")}
                  >
                    <img
                      alt="usersIcon"
                      src={usersIcon}
                      className="w-7 h-7 object-cover"
                    ></img>
                    <p className="ml-1 mt-1">Users</p>
                  </button>

                  <button className="flex  mt-2">
                    <img
                      alt="adminn"
                      src={adminn}
                      className="w-7 h-7 object-cover"
                    ></img>
                    <p className="ml-1 mt-1">Admin</p>
                  </button>
                </div>
              </div>
            )}

            <hr className="w-full ht mt-1 bg-blue-900"></hr>
            <div className="flex flex-col items-start ml-1 mr-1  text-white text-xs font-bold">
              <button className="flex  mt-2"  onClick={(e) => navigate("/feedback")}>
                <img
                  alt="feedback"
                  src={feedback}
                  className="w-6 h-6 object-cover"
                ></img>
                <p className="ml-1 mt-1">Give feedback</p>
              </button>

              <button
                className="flex  mt-2"
                onClick={(e) => navigate("/setting")}
              >
                <img
                  alt="settings"
                  src={settings}
                  className="w-6 h-6 object-cover"
                ></img>
                <p className="ml-1 mt-1">Settings</p>
              </button>

              <button className="flex  mt-2" onClick={(e) => navigate("/help")}>
                <img
                  alt="question"
                  src={question}
                  className="w-6 h-6 object-cover"
                ></img>
                <p className="ml-1 mt-1">Help and support</p>
              </button>

              <button className="flex  mt-2" onClick={(e) => navigate("/community")}>
                <img
                  alt="globeearth"
                  src={globeearth}
                  className="w-6 h-6 object-cover"
                ></img>
                <p className="ml-1 mt-1">Community guildelines</p>
              </button>
            </div>

            <hr className="w-full ht mt-1 bg-blue-900"></hr>

            <div className="flex flex-col mb-10  items-start ml-1 mr-1  text-white text-xs font-bold">
              <button className="flex  mt-3" onClick={(e) => logout()}>
                <img
                  alt="logoutIcon"
                  src={logoutIcon}
                  className="w-7 h-7 object-cover"
                ></img>
                <p className="ml-1 mt-1">Log out</p>
              </button>
            </div>
          </div>
        </nav>
      </div>

      <div>
        <nav className=" sm:hidden sm:h-svh z-50 sm:pb-10 sm:mt-0 sm:pt-0 lg:hidden ">
          <div className="overflow-auto">
            <div className="flex flex-col items-start ml-1 mr-1">
              <div className="flex justify-between w-full">
                <div className=" flex text-blue-200 text-xs font-bold">
                  <img
                    alt="profileIcon"
                    src={profile_photo}
                    className="w-7 h-7 object-cover rounded-full"
                  />
                  <p className="ml-1 mt-1">{firstname}</p>
                </div>

                <div className="flex p-1 ">
                  <button onClick={(e) => {
                    e.stopPropagation();
                    setIsModalOpen(true)
                  }}>

                <IoIosTimer />
                  </button>
                  {/* <img alt="onlineIcon" src={onlineIcon} className="mb-2"></img> */}
                </div>
              </div>

              <div className="flex justify-between w-full  text-blue-200 text-xs font-bold">
                <div className=" flex">
                  <p className="ml-1 mt-1">status:</p>
                  <p className="ml-3 mt-1">Basic Member</p>
                </div>
                  <button onClick={(e) => {e.stopPropagation(); setUpgrade(true)}}>
                <p className="ml-3 mt-1 hover:text-blue-400 active:text-blue-100" >
                  (Upgrade)
                </p></button>
              </div>
                {upgrade && (   <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-300 p-5 px-10 rounded-lg shadow-lg text-center">
            <p className="text-lg font-semibold text-gray-800">Coming Soon</p>
            <button
              className="mt-4 bg-orange-600 text-white px-4 py-2 rounded font-semigold"
              onClick={() =>  setUpgrade(false)}
            >
              OK
            </button>
          </div>
        </div>)}

              <div className="flex justify-between w-full  text-blue-200 text-xs font-bold">
                <div className=" flex">
                  <p className="ml-1 mt-1">You have:</p>
                  <p className="ml-3 mt-1">{gold_balance} Golds</p>
                </div>

                <p
                  className="ml-3 mt-1 hover:text-blue-400 active:text-blue-100 bt"
                  onClick={(e) => navigate("/topup")}
                >
                  (Get More)
                </p>
              </div>
            </div>
            <hr className="w-full ht mt-1 bg-blue-900"></hr>

            <div className="flex flex-col items-start ml-1 mr-1  text-white text-xs font-bold">
              <button
                className="flex  mt-3"
                onClick={(e) => {
                  if (location === `/profile/${postuserid}`) {
                    return;
                  }
                  dispatch(resetprofilebyid());
                  navigate(`/profile/${postuserid}`);
                  // console.log(location)
                }}
              >
                <img
                  alt="profilesidebar"
                  src={personsidebar}
                  className="w-7 h-7 object-cover"
                ></img>
                <p className="ml-1 mt-1">Profile</p>
              </button>

              {Isnotmodel() && (
                <button
                  className="flex  mt-2"
                  onClick={(e) => navigate("/createmodel")}
                >
                  <img
                    alt="addIcon"
                    src={addIcon}
                    className="w-7 h-7 object-cover"
                  ></img>
                  <p className="ml-1 mt-1">Model Application</p>
                </button>
              )}

              {Ismodel() && (
                <button
                  className="flex  mt-2"
                  onClick={(e) => navigate(`/modelbyid/${modelID}`)}
                >
                  <img
                    alt="manageIcon"
                    src={manageIcon}
                    className="w-7 h-7 object-cover"
                  ></img>
                  <p className="ml-1 mt-1">Model portfolio</p>
                </button>
              )}

              <button className="flex  mt-2">
                <img
                  alt="star"
                  src={star}
                  className="w-6 h-6 object-cover"
                ></img>
                <p className="ml-1 mt-1">Following</p>
              </button>

              <button
                className="flex  mt-2"
                onClick={(e) => navigate("/editprofile")}
              >
                <img
                  alt="editIcon"
                  src={editIcon}
                  className="w-7 h-7 object-cover"
                ></img>
                <p className="ml-1 mt-1">Edit porfile</p>
              </button>

              <button className="flex  mt-2" onClick={(e) => { e.stopPropagation();  setLive(true)}}>
                <img
                  alt="video"
                  src={video}
                  className="w-6 h-6 object-cover"
                ></img>
                <p className="ml-1 mt-1">Live</p>
              </button>
            </div>
            {live && (   <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-300 p-5 px-10 rounded-lg shadow-lg text-center">
            <p className="text-lg font-semibold text-gray-800">Coming Soon</p>
            <button
              className="mt-4 bg-orange-600 text-white px-4 py-2 rounded font-semigold"
              onClick={() =>  setLive(false)}
            >
              OK
            </button>
          </div>
        </div>)}
            <hr className="w-full ht mt-1 bg-blue-900"></hr>
            <div className="flex flex-col items-start ml-1 mr-1  text-white text-xs font-bold">
              <button
                className="flex  mt-3"
                onClick={(e) => navigate("/history")}
              >
                <img
                  alt="goldIcon"
                  src={goldIcon}
                  className="w-7 h-7 object-cover"
                ></img>
                <p className="ml-1 mt-1"> Gold Stats</p>
              </button>
            </div>

            {admin && (
              <div>
                <hr className="w-full ht mt-1 bg-blue-900"></hr>

                <div className="flex flex-col items-start ml-1 mr-1  text-white text-xs font-bold">
                  <button className="flex  mt-3">
                    <img
                      alt="reportIcon"
                      src={reportIcon}
                      className="w-7 h-7 object-cover"
                    ></img>
                    <p className="ml-1 mt-1">Reports</p>
                  </button>

                  <button
                    className="flex  mt-2"
                    onClick={(e) => {
                      navigate("/verifymodel");
                    }}
                  >
                    <img
                      alt="verificationIcon"
                      src={verificationIcon}
                      className="w-7 h-7 object-cover"
                    ></img>
                    <p className="ml-1 mt-1">Model verification</p>
                  </button>

                  <button className="flex  mt-2">
                    <img
                      alt="withdrawIcon"
                      src={withdrawIcon}
                      className="w-7 h-7 object-cover"
                    ></img>
                    <p className="ml-1 mt-1">Withdrawal request</p>
                  </button>

                  <button
                    className="flex  mt-2"
                    onClick={(e) => navigate("/viewusers")}
                  >
                    <img
                      alt="usersIcon"
                      src={usersIcon}
                      className="w-7 h-7 object-cover"
                    ></img>
                    <p className="ml-1 mt-1">Users</p>
                  </button>

                  <button className="flex  mt-2">
                    <img
                      alt="adminn"
                      src={adminn}
                      className="w-7 h-7 object-cover"
                    ></img>
                    <p className="ml-1 mt-1">Admin</p>
                  </button>
                </div>
              </div>
            )}

            <hr className="w-full ht mt-1 bg-blue-900"></hr>
            <div className="flex flex-col items-start ml-1 mr-1  text-white text-xs font-bold">
              <button className="flex  mt-2"  onClick={(e) => navigate("/feedback")}>
                <img
                  alt="feedback"
                  src={feedback}
                  className="w-6 h-6 object-cover"
                ></img>
                <p className="ml-1 mt-1">Give feedback</p>
              </button>

              <button className="flex  mt-2">
                <img
                  alt="settings"
                  src={settings}
                  className="w-6 h-6 object-cover"
                ></img>
                <p className="ml-1 mt-1">Settings</p>
              </button>

              <button className="flex  mt-2">
                <img
                  alt="question"
                  src={question}
                  className="w-6 h-6 object-cover"
                ></img>
                <p className="ml-1 mt-1">Help and support</p>
              </button>

              <button className="flex  mt-2">
                <img
                  alt="globeearth"
                  src={globeearth}
                  className="w-6 h-6 object-cover"
                ></img>
                <p className="ml-1 mt-1">Community guildelines</p>
              </button>
            </div>

            <hr className="w-full ht mt-1 bg-blue-900"></hr>

            <div className="flex flex-col mb-10  items-start ml-1 mr-1  text-white text-xs font-bold">
              <button className="flex  mt-3" onClick={(e) => logout()}>
                <img
                  alt="logoutIcon"
                  src={logoutIcon}
                  className="w-7 h-7 object-cover"
                ></img>
                <p className="ml-1 mt-1">Log out</p>
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
